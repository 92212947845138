import React from 'react';
import { Link } from 'react-router-dom';

const legalItems = [
  { label: 'Privacy Policy', href: '/privacy' },
  { label: 'Terms of Service', href: '/terms' },
  { label: 'Cookie Policy', href: '/cookies' },
  { label: 'Accessibility', href: '/accessibility' }
];

const locations = [
  { city: 'Paris', country: 'France' },
  { city: 'London', country: 'UK' },
  { city: 'Berlin', country: 'Germany' }
];

export default function FooterBottom() {
  return (
    <div className="border-t border-primary/10">
      <div className="container mx-auto px-6 py-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-3 md:space-y-0">
          <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-6">
            <p className="text-xs text-white/50">
              © {new Date().getFullYear()} InfiniteMind.TECH
            </p>
            <div className="flex items-center space-x-2 text-white/30">
              {locations.map((location, index) => (
                <React.Fragment key={`${location.city}-${index}`}>
                  <Link 
                    to="/locations" 
                    className="text-xs hover:text-white/50 transition-colors"
                  >
                    {location.city}
                  </Link>
                  {index < locations.length - 1 && <span className="text-xs">•</span>}
                </React.Fragment>
              ))}
            </div>
          </div>
          <nav className="flex flex-wrap justify-center gap-x-6 gap-y-1">
            {legalItems.map((item) => (
              <Link
                key={item.label}
                to={item.href}
                className="text-xs text-white/50 hover:text-white transition-colors"
              >
                {item.label}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}