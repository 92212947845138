import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Menu, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useHeader } from './HeaderContext';

export default function MobileMenu() {
  const { isMenuOpen, setIsMenuOpen } = useHeader();

  const menuItems = [
    { to: '/consulting', label: 'Business Co-Pilot' },
    { to: '/automations', label: 'Business Automations' },
    { to: '/software', label: 'Our Software' },
    { to: '/locations', label: 'Locations' },
    { to: '/careers/positions', label: 'Careers' },
    { to: '/teams', label: 'Team' },
    { to: '/partners', label: 'Partners' },
    { to: '/contact', label: "Let's Talk" }
  ];

  return (
    <>
      <button 
        className="md:hidden text-primary p-2 relative z-50"
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        aria-expanded={isMenuOpen}
        aria-controls="mobile-menu"
        aria-label={isMenuOpen ? "Close menu" : "Open menu"}
      >
        {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
      </button>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            id="mobile-menu"
            className="fixed inset-0 z-40 md:hidden"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.2 }}
          >
            <motion.div 
              className="absolute inset-0 bg-black/90 backdrop-blur-lg"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />

            <nav className="relative h-full flex flex-col pt-24 px-6">
              <div className="space-y-6">
                {menuItems.map((item, index) => (
                  <motion.div
                    key={item.to}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <Link
                      to={item.to}
                      className="text-lg font-light text-white/70 hover:text-white transition-colors block"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      {item.label}
                    </Link>
                  </motion.div>
                ))}
              </div>

              <div className="mt-auto pb-8">
                <div className="text-sm text-white/50">
                  © {new Date().getFullYear()} InfiniteMind.TECH
                </div>
              </div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}