import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Mail, Lock, Eye, EyeOff, ArrowRight, Brain } from 'lucide-react';
import * as Dialog from '@radix-ui/react-dialog';
import FadeIn from '../animations/FadeIn';
import AINetworkAnimation from '../animations/AINetworkAnimation';

interface LoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function LoginModal({ isOpen, onClose }: LoginModalProps) {
  const [isSignUp, setIsSignUp] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Here you would integrate with your authentication service
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulated API call
      onClose();
    } catch (error) {
      console.error('Authentication error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog.Root open={isOpen} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay asChild>
          <motion.div
            className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        </Dialog.Overlay>
        
        <Dialog.Content asChild>
          <motion.div
            className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <AINetworkAnimation />
            
            <div className="h-full flex flex-col relative z-10">
              {/* Header */}
              <div className="p-6 md:p-8 border-b border-primary/10">
                <div className="flex items-center justify-between">
                  <FadeIn>
                    <div>
                      <Dialog.Title className="text-2xl md:text-3xl font-light">
                        {isSignUp ? 'Join InfiniteMind' : 'Welcome Back'}
                      </Dialog.Title>
                      <Dialog.Description className="mt-2 text-white/70">
                        {isSignUp 
                          ? 'Create your account to access exclusive resources and tools'
                          : 'Sign in to access your InfiniteMind resources'
                        }
                      </Dialog.Description>
                    </div>
                  </FadeIn>
                  <Dialog.Close asChild>
                    <button 
                      className="text-white/50 hover:text-white transition-colors"
                      aria-label="Close dialog"
                    >
                      <X className="w-6 h-6" />
                    </button>
                  </Dialog.Close>
                </div>
              </div>

              {/* Content */}
              <div className="flex-1 overflow-y-auto p-6 md:p-8">
                <div className="max-w-md mx-auto">
                  <div className="flex justify-center mb-8">
                    <div className="p-4 bg-primary/10 rounded-full">
                      <Brain className="w-8 h-8 text-primary" />
                    </div>
                  </div>

                  <form onSubmit={handleSubmit} className="space-y-6">
                    {isSignUp && (
                      <>
                        <div>
                          <label className="block text-sm text-white/70 mb-2">
                            Full Name
                          </label>
                          <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
                            placeholder="Enter your name"
                            required
                          />
                        </div>

                        <div>
                          <label className="block text-sm text-white/70 mb-2">
                            Company
                          </label>
                          <input
                            type="text"
                            value={company}
                            onChange={(e) => setCompany(e.target.value)}
                            className="w-full bg-dark rounded-lg px-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
                            placeholder="Enter your company name"
                            required
                          />
                        </div>
                      </>
                    )}

                    <div>
                      <label className="block text-sm text-white/70 mb-2">
                        Email
                      </label>
                      <div className="relative">
                        <Mail className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-white/30" />
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="w-full bg-dark rounded-lg pl-12 pr-4 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
                          placeholder="Enter your email"
                          required
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm text-white/70 mb-2">
                        Password
                      </label>
                      <div className="relative">
                        <Lock className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-white/30" />
                        <input
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="w-full bg-dark rounded-lg pl-12 pr-12 py-3 text-white/90 placeholder:text-white/30 border border-primary/10 focus:outline-none focus:border-primary/30 transition-colors"
                          placeholder="Enter your password"
                          required
                        />
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute right-4 top-1/2 -translate-y-1/2 text-white/30 hover:text-white/70 transition-colors"
                        >
                          {showPassword ? (
                            <EyeOff className="w-5 h-5" />
                          ) : (
                            <Eye className="w-5 h-5" />
                          )}
                        </button>
                      </div>
                    </div>

                    {!isSignUp && (
                      <div className="flex items-center justify-between">
                        <label className="flex items-center space-x-2 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                            className="w-4 h-4 rounded border-primary/30 text-primary focus:ring-primary/30 bg-dark"
                          />
                          <span className="text-sm text-white/70">Remember me</span>
                        </label>
                        <button
                          type="button"
                          className="text-sm text-primary hover:text-primary-light transition-colors"
                        >
                          Forgot password?
                        </button>
                      </div>
                    )}

                    <motion.button
                      type="submit"
                      className="w-full px-8 py-4 bg-primary text-white hover:bg-primary-light transition-colors duration-300 flex items-center justify-center space-x-2 group disabled:opacity-50 disabled:cursor-not-allowed"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      disabled={isLoading}
                    >
                      <span>{isLoading ? 'Please wait...' : isSignUp ? 'Create Account' : 'Sign In'}</span>
                      <ArrowRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
                    </motion.button>

                    <div className="text-center">
                      <button
                        type="button"
                        onClick={() => setIsSignUp(!isSignUp)}
                        className="text-sm text-white/70 hover:text-white transition-colors"
                      >
                        {isSignUp ? 'Already have an account? Sign in' : "Don't have an account? Sign up"}
                      </button>
                    </div>
                  </form>

                  <div className="mt-8 pt-8 border-t border-white/5 text-center">
                    <p className="text-sm text-white/50">
                      By signing {isSignUp ? 'up' : 'in'}, you agree to our{' '}
                      <a href="/terms" className="text-primary hover:text-primary-light">Terms of Service</a>
                      {' '}and{' '}
                      <a href="/privacy" className="text-primary hover:text-primary-light">Privacy Policy</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}