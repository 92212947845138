import React from 'react';
import { motion } from 'framer-motion';

interface LogoHorizontalProps {
  className?: string;
  color?: string;
  animated?: boolean;
  showTagline?: boolean;
}

export default function LogoHorizontal({
  className = '',
  color = 'currentColor',
  animated = true,
  showTagline = true
}: LogoHorizontalProps) {
  // Enhanced infinite circles configuration
  const circles = [
    { size: 50, duration: 8, delay: 0, dashArray: "85,100", opacity: 0.8, reverse: false },
    { size: 40, duration: 10, delay: 0.5, dashArray: "75,100", opacity: 0.7, reverse: true },
    { size: 30, duration: 6, delay: 1, dashArray: "65,100", opacity: 0.6, reverse: false }
  ];

  return (
    <div className={`inline-flex flex-col ${className}`}>
      <motion.div
        initial={animated ? { opacity: 0, x: -20 } : { opacity: 1, x: 0 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="flex flex-col items-center relative"
      >
        {/* Infinite Animated Circles */}
        <div className="absolute -left-16 top-1/2 -translate-y-1/2">
          {circles.map((circle, index) => (
            <motion.div
              key={index}
              className="absolute left-0 top-1/2 -translate-y-1/2"
              style={{
                width: circle.size,
                height: circle.size,
                marginLeft: index * 6
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: circle.delay }}
            >
              {/* Enhanced Glow Effect */}
              <motion.div 
                className="absolute inset-0 rounded-full"
                style={{
                  background: `radial-gradient(circle at center, rgba(255,107,0,0.4) 0%, transparent 70%)`,
                  filter: 'blur(6px)'
                }}
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.4, 0.6, 0.4]
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              <svg
                viewBox="0 0 100 100"
                className="w-full h-full absolute"
                style={{ transform: 'rotate(-90deg)' }}
              >
                {/* Enhanced Shine Effect */}
                <defs>
                  <linearGradient id={`shine-${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="rgba(255,107,0,0.9)" />
                    <stop offset="50%" stopColor="rgba(255,133,51,1)" />
                    <stop offset="100%" stopColor="rgba(255,107,0,0.9)" />
                  </linearGradient>
                  
                  {/* Radial Gradient for Extra Glow */}
                  <radialGradient id={`glow-${index}`}>
                    <stop offset="0%" stopColor="rgba(255,107,0,0.4)" />
                    <stop offset="100%" stopColor="rgba(255,107,0,0)" />
                  </radialGradient>
                </defs>

                {/* Glow Base */}
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill={`url(#glow-${index})`}
                  className="opacity-50"
                />

                {/* Base Circle with Pulse */}
                <motion.circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke="rgba(255,107,0,0.3)"
                  strokeWidth="4"
                  className="filter drop-shadow-lg"
                  animate={{
                    opacity: [0.3, 0.5, 0.3]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity,
                    ease: "easeInOut"
                  }}
                />

                {/* Infinite Rotating Circle */}
                <motion.circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke={`url(#shine-${index})`}
                  strokeWidth="4"
                  strokeDasharray={circle.dashArray}
                  className="filter drop-shadow-lg"
                  style={{
                    filter: 'drop-shadow(0 0 6px rgba(255,107,0,0.6))'
                  }}
                  animate={{
                    rotate: circle.reverse ? [-360, 0] : [0, 360],
                    strokeDashoffset: circle.reverse ? ['100%', '0%'] : ['0%', '100%']
                  }}
                  transition={{
                    duration: circle.duration,
                    repeat: Infinity,
                    ease: "linear",
                    delay: circle.delay
                  }}
                />

                {/* Particle Effects */}
                {[...Array(3)].map((_, i) => (
                  <motion.circle
                    key={i}
                    cx="50"
                    cy="50"
                    r="2"
                    fill="rgba(255,107,0,0.8)"
                    animate={{
                      cx: [50, 50 + Math.cos((i * 120) * Math.PI / 180) * 45, 50],
                      cy: [50, 50 + Math.sin((i * 120) * Math.PI / 180) * 45, 50],
                      opacity: [0, 1, 0]
                    }}
                    transition={{
                      duration: circle.duration / 2,
                      repeat: Infinity,
                      delay: (i * circle.duration) / 6,
                      ease: "linear"
                    }}
                  />
                ))}
              </svg>
            </motion.div>
          ))}
        </div>

        {/* Logo Text */}
        <span className="text-3xl md:text-4xl font-light tracking-wider relative">
          <span className="font-normal">Infinite</span>
          <span className="text-primary">Mind</span>
          <motion.div 
            className="absolute -bottom-1 left-0 w-full h-[2px] bg-primary"
            initial={animated ? { scaleX: 0 } : { scaleX: 1 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          />
        </span>
        
        {showTagline && (
          <motion.div
            initial={animated ? { opacity: 0, y: 10 } : { opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-1 text-[0.75rem] tracking-wide text-current/70"
          >
            Shaping Digital Future
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}