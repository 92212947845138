import React from 'react';
import { motion } from 'framer-motion';
import FooterBrand from './FooterBrand';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import FooterBottom from './FooterBottom';
import FooterAnimation from '../../animations/FooterAnimation';

export default function Footer() {
  return (
    <footer className="bg-dark-lighter relative overflow-hidden">
      <FooterAnimation />
      
      <div className="container mx-auto px-4 sm:px-6 py-12 sm:py-20 relative">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-12">
          <FooterBrand />
          <FooterLinks />
          <FooterNewsletter />
        </div>
      </div>
      
      <FooterBottom />
    </footer>
  );
}