import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Github, Twitter, Linkedin, Instagram } from 'lucide-react';
import { LogoVertical } from '../../logo';

const socialLinks = [
  { name: 'LinkedIn', icon: Linkedin, href: 'https://linkedin.com/company/infinitemind' },
  { name: 'Twitter', icon: Twitter, href: 'https://twitter.com/infinitemind' },
  { name: 'GitHub', icon: Github, href: 'https://github.com/infinitemind' },
  { name: 'Instagram', icon: Instagram, href: 'https://instagram.com/infinitemind.tech' }
];

export default function FooterBrand() {
  return (
    <div className="lg:col-span-3">
      <div className="inline-block">
        <LogoVertical color="white" showTagline />
      </div>
      <div className="mt-6 flex space-x-3">
        {socialLinks.map((social) => (
          <motion.a
            key={social.name}
            href={social.href}
            target="_blank"
            rel="noopener noreferrer"
            className="p-2 bg-dark rounded-lg text-white/70 hover:text-primary transition-colors duration-300"
            whileHover={{ y: -3 }}
            aria-label={`Follow us on ${social.name}`}
          >
            <social.icon className="w-4 h-4" />
          </motion.a>
        ))}
      </div>
      <p className="mt-6 text-sm text-white/50 max-w-sm">
        Empowering businesses with AI-driven solutions for digital transformation and sustainable growth.
      </p>
    </div>
  );
}