import { createContext, useContext, useState } from 'react';

interface HeaderContextType {
  actionType: 'contact' | 'project' | 'work' | null;
  setActionType: (type: 'contact' | 'project' | 'work' | null) => void;
  isCareersModalOpen: boolean;
  setCareersModalOpen: (open: boolean) => void;
  isLocationsModalOpen: boolean;
  setLocationsModalOpen: (open: boolean) => void;
  isTeamModalOpen: boolean;
  setTeamModalOpen: (open: boolean) => void;
  isMenuOpen: boolean;
  setIsMenuOpen: (open: boolean) => void;
}

const HeaderContext = createContext<HeaderContextType | undefined>(undefined);

export function HeaderProvider({ children }: { children: React.ReactNode }) {
  const [actionType, setActionType] = useState<'contact' | 'project' | 'work' | null>(null);
  const [isCareersModalOpen, setCareersModalOpen] = useState(false);
  const [isLocationsModalOpen, setLocationsModalOpen] = useState(false);
  const [isTeamModalOpen, setTeamModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <HeaderContext.Provider value={{
      actionType,
      setActionType,
      isCareersModalOpen,
      setCareersModalOpen,
      isLocationsModalOpen,
      setLocationsModalOpen,
      isTeamModalOpen,
      setTeamModalOpen,
      isMenuOpen,
      setIsMenuOpen
    }}>
      {children}
    </HeaderContext.Provider>
  );
}

export function useHeader() {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('useHeader must be used within a HeaderProvider');
  }
  return context;
}