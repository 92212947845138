import React from 'react';
import { Link } from 'react-router-dom';

const sections = {
  Company: [
    { label: 'About Us', href: '/about' },
    { label: 'Careers', href: '/careers' },
    { label: 'Contact', href: '/contact' },
    { label: 'Locations', href: '/locations' },
    { label: 'Team', href: '/teams' }
  ],
  Solutions: [
    { label: 'Business Co-Pilot', href: '/consulting' },
    { label: 'Business Automations', href: '/automations' },
    { label: 'Our Software', href: '/software' }
  ]
};

export default function FooterLinks() {
  return (
    <div className="lg:col-span-6 grid grid-cols-1 md:grid-cols-2 gap-8">
      {Object.entries(sections).map(([category, items]) => (
        <div key={category}>
          <h3 className="text-base font-light text-white mb-4">{category}</h3>
          <ul className="space-y-2">
            {items.map((item) => (
              <li key={item.label}>
                <Link 
                  to={item.href}
                  className="text-sm text-white/70 hover:text-white transition-colors duration-300"
                >
                  {item.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}