import React from 'react';
import { motion } from 'framer-motion';

interface LogoVerticalProps {
  className?: string;
  color?: string;
  animated?: boolean;
  showTagline?: boolean;
}

export default function LogoVertical({
  className = '',
  color = 'currentColor',
  animated = true,
  showTagline = false
}: LogoVerticalProps) {
  // Animated circles configuration
  const circles = [
    { size: 30, duration: 4, delay: 0, dashArray: "70,100", opacity: 0.8 },
    { size: 24, duration: 5, delay: 0.5, dashArray: "60,100", opacity: 0.7 },
    { size: 18, duration: 3, delay: 1, dashArray: "50,100", opacity: 0.6 }
  ];

  return (
    <div className={`flex flex-col items-center ${className}`}>
      <motion.div
        initial={animated ? { opacity: 0, y: -20 } : { opacity: 1, y: 0 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="text-center relative"
      >
        {/* Animated Circles */}
        <div className="absolute -left-8 top-1/2 -translate-y-1/2">
          {circles.map((circle, index) => (
            <motion.div
              key={index}
              className="absolute left-0 top-1/2 -translate-y-1/2"
              style={{
                width: circle.size,
                height: circle.size,
                marginLeft: index * 4
              }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: circle.delay }}
            >
              {/* Glow Effect */}
              <div 
                className="absolute inset-0 rounded-full"
                style={{
                  background: `radial-gradient(circle at center, rgba(255,107,0,0.3) 0%, transparent 70%)`,
                  filter: 'blur(4px)'
                }}
              />

              <svg
                viewBox="0 0 100 100"
                className="w-full h-full absolute"
                style={{ transform: 'rotate(-90deg)' }}
              >
                {/* Shine Effect */}
                <defs>
                  <linearGradient id={`shine-v-${index}`} x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="rgba(255,107,0,0.8)" />
                    <stop offset="50%" stopColor="rgba(255,133,51,1)" />
                    <stop offset="100%" stopColor="rgba(255,107,0,0.8)" />
                  </linearGradient>
                </defs>

                {/* Base Circle */}
                <circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke="rgba(255,107,0,0.2)"
                  strokeWidth="4"
                  className="filter drop-shadow-lg"
                />

                {/* Animated Circle */}
                <motion.circle
                  cx="50"
                  cy="50"
                  r="45"
                  fill="none"
                  stroke={`url(#shine-v-${index})`}
                  strokeWidth="4"
                  strokeDasharray={circle.dashArray}
                  className="filter drop-shadow-lg"
                  style={{
                    filter: 'drop-shadow(0 0 4px rgba(255,107,0,0.5))'
                  }}
                  animate={{
                    rotate: [0, 360],
                    strokeDashoffset: ['0%', '100%']
                  }}
                  transition={{
                    duration: circle.duration,
                    repeat: Infinity,
                    ease: "linear",
                    delay: circle.delay
                  }}
                />
              </svg>
            </motion.div>
          ))}
        </div>

        <div className="text-2xl font-light tracking-wider relative inline-block">
          <span className="font-normal">Infinite</span>Mind<span className="text-primary">.TECH</span>
          <motion.div 
            className="absolute -bottom-1 left-0 w-full h-[1px] bg-primary"
            initial={animated ? { scaleX: 0 } : { scaleX: 1 }}
            animate={{ scaleX: 1 }}
            transition={{ duration: 0.5, delay: 0.4 }}
          />
        </div>
        
        {showTagline && (
          <motion.div
            initial={animated ? { opacity: 0, y: 10 } : { opacity: 1, y: 0 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-1 text-[0.72rem] tracking-wide text-current/70"
          >
            Shaping Digital Future
          </motion.div>
        )}
      </motion.div>
    </div>
  );
}