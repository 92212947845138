import { useState, useCallback } from 'react';

interface Message {
  role: 'user' | 'assistant';
  content: string;
}

interface AIAgentState {
  messages: Message[];
  isLoading: boolean;
  error: string | null;
}

export function useAIAgent() {
  const [state, setState] = useState<AIAgentState>({
    messages: [
      {
        role: 'assistant',
        content: 'Hello! I\'m InfiniteMind\'s AI assistant. How can I help you today?'
      }
    ],
    isLoading: false,
    error: null
  });

  const sendMessage = useCallback(async (content: string) => {
    setState(prev => ({
      ...prev,
      messages: [...prev.messages, { role: 'user', content }],
      isLoading: true,
      error: null
    }));

    try {
      // Simulate AI response with predefined answers
      const response = await simulateAIResponse(content);
      
      setState(prev => ({
        ...prev,
        messages: [...prev.messages, { role: 'assistant', content: response }],
        isLoading: false
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        isLoading: false,
        error: 'Failed to get response. Please try again.'
      }));
    }
  }, []);

  return {
    messages: state.messages,
    isLoading: state.isLoading,
    error: state.error,
    sendMessage
  };
}

// Simulate AI responses based on keywords
async function simulateAIResponse(message: string): Promise<string> {
  // Add artificial delay
  await new Promise(resolve => setTimeout(resolve, 1000));

  const lowercaseMessage = message.toLowerCase();

  // Services related queries
  if (lowercaseMessage.includes('services') || lowercaseMessage.includes('offer')) {
    return `We offer a comprehensive range of services including:
    - Business Co-Pilot: Strategic consulting and digital transformation
    - Business Automation: AI-powered process automation
    - Custom Software Solutions
    
Would you like to learn more about any specific service?`;
  }

  // Pricing related queries
  if (lowercaseMessage.includes('price') || lowercaseMessage.includes('cost')) {
    return `Our pricing is customized based on your specific needs and project scope. I'd be happy to schedule a consultation where we can discuss your requirements and provide a detailed quote. Would you like me to help you schedule a meeting with our team?`;
  }

  // Meeting/Contact related queries
  if (lowercaseMessage.includes('meeting') || lowercaseMessage.includes('contact') || lowercaseMessage.includes('schedule')) {
    return `I can help you schedule a meeting with our team. Please provide:
    1. Your preferred date and time
    2. Brief description of what you'd like to discuss
    
Alternatively, you can reach us at contact@infinitemind.tech or call +33 6 75 35 39 65.`;
  }

  // Location related queries
  if (lowercaseMessage.includes('location') || lowercaseMessage.includes('office')) {
    return `Our global headquarters is located in Paris, France, and we have offices across Europe including:
    - London, UK
    - Berlin, Germany
    - Amsterdam, Netherlands
    - Madrid, Spain
    
Would you like specific details about any of our locations?`;
  }

  // Technology related queries
  if (lowercaseMessage.includes('technology') || lowercaseMessage.includes('tech') || lowercaseMessage.includes('ai')) {
    return `We leverage cutting-edge technologies including:
    - Advanced AI/ML models
    - Cloud computing platforms
    - Enterprise automation tools
    - Custom software development
    
Would you like to learn more about our technical capabilities?`;
  }

  // Default response
  return `I understand you're interested in learning more about InfiniteMind. Could you please provide more specific details about what you'd like to know? I can help with information about our:
  - Services and solutions
  - Technology capabilities
  - Pricing and packages
  - Meeting scheduling
  - Office locations`;
}