import React from 'react';
import { Outlet } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../ui/ErrorFallback';
import Header from './Header';
import Footer from './Footer';
import ProgressBar from '../ui/ProgressBar';
import AIAgent from '../chat/AIAgent';
import { useSmoothScroll } from '../../hooks/useSmoothScroll';
import PageBackground from '../ui/PageBackground';

export default function Layout() {
  useSmoothScroll();

  return (
    <div className="min-h-screen bg-dark flex flex-col relative">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link rel="icon" href="/favicon.svg" />
        <link rel="apple-touch-icon" href="/logo192.png" />
        <link rel="manifest" href="/manifest.json" />
      </Helmet>

      <PageBackground 
        variant="particles" 
        intensity={window.innerWidth < 768 ? 3 : 2.5} 
        color="#FF6B00" 
      />

      <ProgressBar />
      <Header />
      
      <main className="flex-1 relative">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <Outlet />
        </ErrorBoundary>
      </main>
      
      <Footer />
      <AIAgent />
    </div>
  );
}