import React from 'react';
import { Link } from 'react-router-dom';
import { useScrollPosition } from '../../hooks/useScrollPosition';
import { LogoHorizontal } from '../logo';
import HeaderNav from './Header/HeaderNav';
import MobileMenu from './Header/MobileMenu';
import { HeaderProvider } from './Header/HeaderContext';

export default function Header() {
  const { scrollPosition } = useScrollPosition();
  const isScrolled = scrollPosition > 50;

  return (
    <HeaderProvider>
      <header 
        className={`fixed w-full z-50 transition-all duration-500 ${
          isScrolled ? 'bg-dark/90 backdrop-blur-sm' : 'bg-transparent'
        }`}
      >
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <Link to="/">
              <LogoHorizontal color="white" />
            </Link>
            <HeaderNav />
            <MobileMenu />
          </div>
        </div>
      </header>
    </HeaderProvider>
  );
}