import React, { lazy, Suspense } from 'react';
import { createBrowserRouter } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Loading from './components/ui/Loading';
import ErrorFallback from './components/ui/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

// Lazy load pages
const HomePage = lazy(() => import('./pages/home'));
const ConsultingPage = lazy(() => import('./pages/consulting'));
const ConsultingApproachPage = lazy(() => import('./pages/consulting/approach'));
const BusinessAutomationsPage = lazy(() => import('./pages/business-automations'));
const SoftwarePage = lazy(() => import('./pages/software'));
const LocationsPage = lazy(() => import('./pages/locations'));
const CareersPage = lazy(() => import('./pages/careers'));
const TeamsPage = lazy(() => import('./pages/teams'));
const PartnersPage = lazy(() => import('./pages/partners'));
const ContactPage = lazy(() => import('./pages/contact'));
const PositionsPage = lazy(() => import('./pages/careers/positions'));

// Legal Pages
const PrivacyPolicyPage = lazy(() => import('./pages/legal/privacy-policy'));
const TermsOfServicePage = lazy(() => import('./pages/legal/terms-of-service'));
const CookiePolicyPage = lazy(() => import('./pages/legal/cookie-policy'));
const AccessibilityPage = lazy(() => import('./pages/legal/accessibility'));

// Wrap component with error boundary and suspense
const withErrorBoundary = (Component: React.ComponentType) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<Loading />}>
      <Component />
    </Suspense>
  </ErrorBoundary>
);

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    errorElement: <ErrorFallback />,
    children: [
      { 
        index: true, 
        element: withErrorBoundary(HomePage)
      },
      { 
        path: '/consulting', 
        element: withErrorBoundary(ConsultingPage)
      },
      {
        path: '/consulting/approach',
        element: withErrorBoundary(ConsultingApproachPage)
      },
      {
        path: '/automations',
        element: withErrorBoundary(BusinessAutomationsPage)
      },
      {
        path: '/software',
        element: withErrorBoundary(SoftwarePage)
      },
      { 
        path: '/locations', 
        element: withErrorBoundary(LocationsPage)
      },
      { 
        path: '/careers', 
        element: withErrorBoundary(CareersPage)
      },
      { 
        path: '/careers/positions', 
        element: withErrorBoundary(PositionsPage)
      },
      { 
        path: '/teams', 
        element: withErrorBoundary(TeamsPage)
      },
      {
        path: '/partners',
        element: withErrorBoundary(PartnersPage)
      },
      { 
        path: '/contact', 
        element: withErrorBoundary(ContactPage)
      },
      // Legal Routes
      {
        path: '/privacy',
        element: withErrorBoundary(PrivacyPolicyPage)
      },
      {
        path: '/terms',
        element: withErrorBoundary(TermsOfServicePage)
      },
      {
        path: '/cookies',
        element: withErrorBoundary(CookiePolicyPage)
      },
      {
        path: '/accessibility',
        element: withErrorBoundary(AccessibilityPage)
      }
    ]
  }
]);