import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Lock, Bot, Laptop } from 'lucide-react';
import DiagonalArrow from '../../ui/DiagonalArrow';
import LoginModal from '../../auth/LoginModal';

export default function HeaderNav() {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  return (
    <nav className="hidden md:flex flex-col items-end">
      {/* Top Level Menu */}
      <div className="flex items-center space-x-8 mb-4">
        <div className="relative">
          <Link 
            to="/consulting" 
            className="flex items-center space-x-2 text-base text-primary hover:text-primary-light transition-colors duration-300"
          >
            <span>Business Co-Pilot</span>
            <DiagonalArrow size={16} className="transform group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>
        
        <div className="relative">
          <Link 
            to="/automations" 
            className="flex items-center space-x-2 text-base text-white/70 hover:text-white transition-colors duration-300"
          >
            <span>Business Automations</span>
            <Bot size={16} className="transform group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>

        <div className="relative">
          <Link 
            to="/software" 
            className="flex items-center space-x-2 text-base text-white/70 hover:text-white transition-colors duration-300"
          >
            <span>Our Software</span>
            <Laptop size={16} className="transform group-hover:translate-x-1 transition-transform" />
          </Link>
        </div>
      </div>

      {/* Separator Line */}
      <div className="w-full h-px bg-gradient-to-r from-transparent via-white/10 to-transparent mb-4" />

      {/* Bottom Level Menu */}
      <div className="flex items-center space-x-6">
        <Link 
          to="/locations"
          className="text-sm text-white/70 hover:text-white transition-colors duration-300"
        >
          Locations
        </Link>
        <Link 
          to="/careers/positions"
          className="text-sm text-white/70 hover:text-white transition-colors duration-300"
        >
          Careers
        </Link>
        <Link 
          to="/teams"
          className="text-sm text-white/70 hover:text-white transition-colors duration-300"
        >
          Team
        </Link>

        <div className="flex items-center space-x-4 border-l border-white/10 pl-4">
          <Link 
            to="/contact"
            className="px-4 py-1.5 text-sm border border-primary text-primary hover:bg-primary hover:text-white transition-all duration-300"
          >
            Let's Talk
          </Link>

          <button 
            onClick={() => setLoginModalOpen(true)}
            className="group relative flex items-center space-x-2 text-sm text-white/70 hover:text-white transition-all duration-300"
          >
            {/* Glowing background effect */}
            <div className="absolute inset-0 bg-gradient-to-r from-primary/0 via-primary/10 to-primary/0 opacity-0 group-hover:opacity-100 blur transition-all duration-500" />
            
            {/* Content */}
            <div className="relative flex items-center space-x-2">
              <Lock className="w-4 h-4 transform group-hover:rotate-12 transition-transform duration-300" />
              <span className="transform group-hover:translate-x-0.5 transition-transform duration-300 bg-gradient-to-r from-primary via-primary-light to-white bg-clip-text text-transparent opacity-0 group-hover:opacity-100">
                IM Resources
              </span>
            </div>

            {/* Animated underline */}
            <div className="absolute bottom-0 left-0 w-0 h-px bg-gradient-to-r from-primary via-primary-light to-white group-hover:w-full transition-all duration-500" />
          </button>
        </div>
      </div>

      <LoginModal 
        isOpen={isLoginModalOpen}
        onClose={() => setLoginModalOpen(false)}
      />
    </nav>
  );
}