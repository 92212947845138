import React, { useEffect, useRef, useMemo } from 'react';
import { motion } from 'framer-motion';

interface PageBackgroundProps {
  variant?: 'default' | 'gradient' | 'particles' | 'network';
  intensity?: number;
  color?: string;
  className?: string;
}

export default function PageBackground({ 
  variant = 'default',
  intensity = 0.5,
  color = '#FF6B00',
  className = ''
}: PageBackgroundProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  // Memoize particle count based on screen size
  const particleCount = useMemo(() => {
    if (typeof window === 'undefined') return 100;
    return window.innerWidth < 768 ? 100 : 200;
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d', { alpha: true });
    if (!ctx) return;

    // Enable hardware acceleration
    ctx.imageSmoothingEnabled = false;

    const setCanvasSize = () => {
      const dpr = window.devicePixelRatio || 1;
      const rect = canvas.getBoundingClientRect();
      
      // Ensure dimensions are non-zero
      const width = Math.max(1, rect.width);
      const height = Math.max(1, rect.height);
      
      canvas.width = width * dpr;
      canvas.height = height * dpr;
      ctx.scale(dpr, dpr);
      
      // Reset canvas transform on resize
      ctx.setTransform(dpr, 0, 0, dpr, 0, 0);
    };

    // Initial size setup
    setCanvasSize();

    // Debounced resize handler
    let resizeTimeout: NodeJS.Timeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(setCanvasSize, 200);
    };
    
    window.addEventListener('resize', handleResize);

    // Create particles
    const particles = new Array(particleCount).fill(null).map(() => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      vx: (Math.random() - 0.5) * 2,
      vy: (Math.random() - 0.5) * 2,
      size: Math.random() * (window.innerWidth < 768 ? 4 : 8) + 2,
      color,
      angle: Math.random() * Math.PI * 2,
      pulseSpeed: 0.02 + Math.random() * 0.02,
      connections: new Set<number>() // Track connections for optimization
    }));

    let animationFrame: number;
    let lastTime = 0;

    const animate = (currentTime: number) => {
      if (!ctx || !canvas) return;

      const deltaTime = currentTime - lastTime;
      lastTime = currentTime;
      
      // Clear with optimized alpha
      ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Update and draw particles
      particles.forEach((particle, index) => {
        // Optimized movement
        particle.angle += particle.pulseSpeed * deltaTime * 0.01;
        particle.x += (particle.vx + Math.sin(particle.angle)) * deltaTime * 0.05;
        particle.y += (particle.vy + Math.cos(particle.angle)) * deltaTime * 0.05;

        // Efficient boundary checking
        if (particle.x < 0) particle.x = canvas.width;
        else if (particle.x > canvas.width) particle.x = 0;
        if (particle.y < 0) particle.y = canvas.height;
        else if (particle.y > canvas.height) particle.y = 0;

        // Optimized glow effect
        const glowSize = particle.size * (window.innerWidth < 768 ? 8 : 12);
        const glow = ctx.createRadialGradient(
          particle.x, particle.y, 0,
          particle.x, particle.y, glowSize
        );

        const baseOpacity = (Math.sin(currentTime * 0.001 + particle.angle) + 1) * 0.5;
        const [r, g, b] = [
          parseInt(color.slice(1,3), 16),
          parseInt(color.slice(3,5), 16),
          parseInt(color.slice(5,7), 16)
        ];

        glow.addColorStop(0, `rgba(${r},${g},${b},${baseOpacity * intensity})`);
        glow.addColorStop(0.4, `rgba(${r},${g},${b},${0.3 * intensity})`);
        glow.addColorStop(1, 'transparent');

        ctx.beginPath();
        ctx.fillStyle = glow;
        ctx.arc(particle.x, particle.y, glowSize, 0, Math.PI * 2);
        ctx.fill();

        // Optimized particle core
        ctx.beginPath();
        ctx.fillStyle = `rgba(${r},${g},${b},${intensity})`;
        ctx.arc(particle.x, particle.y, particle.size, 0, Math.PI * 2);
        ctx.fill();

        // Optimized connections
        particle.connections.clear();
        for (let i = index + 1; i < particles.length; i++) {
          const other = particles[i];
          const dx = other.x - particle.x;
          const dy = other.y - particle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < (window.innerWidth < 768 ? 150 : 200)) {
            particle.connections.add(i);
            const opacity = (1 - distance / (window.innerWidth < 768 ? 150 : 200)) * intensity * baseOpacity;
            
            ctx.beginPath();
            ctx.strokeStyle = `rgba(${r},${g},${b},${opacity})`;
            ctx.lineWidth = 2 * opacity;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(other.x, other.y);
            ctx.stroke();
          }
        }
      });

      animationFrame = requestAnimationFrame(animate);
    };

    // Start animation
    animate(0);

    return () => {
      clearTimeout(resizeTimeout);
      cancelAnimationFrame(animationFrame);
      window.removeEventListener('resize', handleResize);
    };
  }, [variant, intensity, color, particleCount]);

  return (
    <div className={`absolute inset-0 overflow-hidden pointer-events-none ${className}`}>
      <canvas
        ref={canvasRef}
        className="w-full h-full"
        style={{ 
          willChange: 'transform',
          transform: 'translateZ(0)',
          backfaceVisibility: 'hidden'
        }}
      />
      <motion.div 
        className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black/30"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
      />
    </div>
  );
}