import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { motion } from 'framer-motion';

export default function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';

  return (
    <div className="min-h-screen bg-dark flex items-center justify-center p-4">
      <motion.div 
        className="bg-dark-lighter p-8 rounded-lg max-w-2xl w-full text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h1 className="text-2xl font-light mb-4">Something went wrong</h1>
        <p className="text-white/70 mb-6">
          {errorMessage}
        </p>
        <button
          onClick={resetErrorBoundary}
          className="px-6 py-2 bg-primary text-white hover:bg-primary-light transition-colors"
        >
          Try again
        </button>
      </motion.div>
    </div>
  );
}