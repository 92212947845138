import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Package, ArrowRight, X, Brain, Database, Cpu, Server, GraduationCap, Building2, Users, Workflow, Shield, Maximize2, Download } from 'lucide-react';
import { Link } from 'react-router-dom';
import FadeIn from '../animations/FadeIn';
import * as Dialog from '@radix-ui/react-dialog';

const visualShowcase = [
  {
    image: 'https://images.unsplash.com/photo-1677442136019-21780ecad995?auto=format&fit=crop&q=80&w=800',
    title: 'AI Innovation',
    description: 'Cutting-edge AI solutions'
  },
  {
    image: 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80&w=800',
    title: 'Digital Excellence',
    description: 'Next-gen technology'
  },
  {
    image: 'https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&q=80&w=800',
    title: 'Expert Teams',
    description: 'Global talent network'
  }
];

const offers = [
  {
    icon: <Brain className="w-8 h-8" />,
    title: 'IM - Transformation Foundation',
    description: 'Strategic AI transformation and change management',
    link: '/consulting/transformation',
    subItems: [
      'Change Management',
      'AI Strategy Development',
      'Business Model Redesign',
      'Cultural Transformation'
    ]
  },
  {
    icon: <Database className="w-8 h-8" />,
    title: 'IM - Data Foundation',
    description: 'Enterprise data strategy and management',
    link: '/consulting/data',
    subItems: [
      'Data Governance',
      'Data Architecture',
      'Data Quality',
      'Data Security'
    ]
  },
  {
    icon: <Cpu className="w-8 h-8" />,
    title: 'IM - AI Foundation',
    description: 'Advanced AI implementation and optimization',
    link: '/consulting/ai',
    subItems: [
      'Machine Learning',
      'Natural Language Processing',
      'Computer Vision',
      'Predictive Analytics'
    ]
  },
  {
    icon: <Server className="w-8 h-8" />,
    title: 'IM - Platforms Foundation',
    description: 'Digital platform development and integration',
    link: '/consulting/platforms',
    subItems: [
      'Cloud Infrastructure',
      'API Development',
      'Microservices',
      'Platform Security'
    ]
  },
  {
    icon: <GraduationCap className="w-8 h-8" />,
    title: 'IM - Academy Foundation',
    description: 'Training and skill development programs',
    link: '/consulting/academy',
    subItems: [
      'AI Training Programs',
      'Technical Certifications',
      'Leadership Development',
      'Digital Skills'
    ]
  },
  {
    icon: <Building2 className="w-8 h-8" />,
    title: 'IM - Industries Foundation',
    description: 'Industry-specific AI solutions',
    link: '/consulting/industries',
    subItems: [
      'Financial Services & Banking',
      'Healthcare & Life Sciences',
      'Manufacturing & Industry 4.0',
      'Retail & E-commerce',
      'Technology & Software',
      'Energy & Utilities',
      'Transportation & Logistics',
      'Government & Public Sector',
      'Education & EdTech',
      'Media & Entertainment',
      'Real Estate & Construction',
      'Professional Services',
      'Telecommunications',
      'Agriculture & AgriTech',
      'Hospitality & Tourism'
    ]
  },
  {
    icon: <Users className="w-8 h-8" />,
    title: 'IM - Departments Foundation',
    description: 'Department-focused optimization',
    link: '/consulting/departments',
    subItems: [
      'Executive Leadership & Strategy',
      'Human Resources & Talent',
      'Finance & Accounting',
      'Marketing & Communications',
      'Sales & Business Development',
      'Operations & Supply Chain',
      'Information Technology',
      'Research & Development',
      'Customer Service & Support',
      'Legal & Compliance',
      'Product Development',
      'Quality Assurance',
      'Business Intelligence',
      'Procurement & Sourcing',
      'Project Management Office'
    ]
  },
  {
    icon: <Workflow className="w-8 h-8" />,
    title: 'IM - Processes Foundation',
    description: 'Process automation and optimization',
    link: '/consulting/processes',
    subItems: [
      // Core Business Processes
      'Strategic Planning & Management',
      'Financial Planning & Analysis',
      'Risk Management & Compliance',
      'Performance Management',
      'Quality Assurance & Control',
      
      // Operations & Supply Chain
      'Supply Chain Management',
      'Inventory Management',
      'Procurement & Sourcing',
      'Logistics & Distribution',
      'Warehouse Management',
      
      // Sales & Marketing
      'Sales Pipeline Management',
      'Marketing Campaign Management',
      'Customer Relationship Management',
      'Lead Generation & Nurturing',
      'Market Research & Analysis',
      
      // Human Resources
      'Recruitment & Onboarding',
      'Performance Evaluation',
      'Training & Development',
      'Compensation & Benefits',
      'Employee Relations',
      
      // Finance & Accounting
      'Accounts Payable & Receivable',
      'Budgeting & Forecasting',
      'Financial Reporting',
      'Tax Management',
      'Asset Management',
      
      // IT & Technology
      'IT Service Management',
      'Software Development Lifecycle',
      'Infrastructure Management',
      'Cybersecurity Operations',
      'Data Management',
      
      // Customer Service
      'Customer Support Management',
      'Complaint Resolution',
      'Service Level Management',
      'Customer Feedback Management',
      'Quality Monitoring',
      
      // Product Development
      'Product Lifecycle Management',
      'Research & Development',
      'Design & Prototyping',
      'Testing & Validation',
      'Product Launch Management',
      
      // Project Management
      'Project Planning & Execution',
      'Resource Allocation',
      'Timeline Management',
      'Budget Management',
      'Stakeholder Management',
      
      // Legal & Compliance
      'Contract Management',
      'Regulatory Compliance',
      'Intellectual Property Management',
      'Legal Documentation',
      'Audit Management',
      
      // Facilities & Administration
      'Facility Management',
      'Asset Maintenance',
      'Space Planning',
      'Security Management',
      'Utilities Management',
      
      // Knowledge Management
      'Document Management',
      'Knowledge Base Management',
      'Training Material Management',
      'Best Practices Documentation',
      'Process Documentation',
      
      // Communication
      'Internal Communications',
      'External Communications',
      'Crisis Communication',
      'Stakeholder Communication',
      'Change Communication',
      
      // Innovation Management
      'Innovation Pipeline Management',
      'Idea Management',
      'Innovation Portfolio Management',
      'Technology Assessment',
      'Innovation Implementation',
      
      // Sustainability
      'Environmental Management',
      'Energy Management',
      'Waste Management',
      'Sustainability Reporting',
      'Green Initiative Management'
    ]
  },
  {
    icon: <Shield className="w-8 h-8" />,
    title: 'IM - Compliancy & Ethical Foundation',
    description: 'Regulatory compliance and ethical AI',
    link: '/consulting/compliance',
    subItems: [
      'Regulatory Compliance',
      'Ethical AI Framework',
      'Data Privacy',
      'Risk Management'
    ]
  }
];

export default function OffersSidebar() {
  const [isVisible, setIsVisible] = useState(false);
  const [expandedItem, setExpandedItem] = useState<string | null>('IM - Transformation Foundation');
  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleCatalogRequest = () => {
    // Prepare email content
    const subject = encodeURIComponent('Request for Services Catalog');
    const body = encodeURIComponent('I would like to request the InfiniteMind Services Catalog.\n\nBest regards,');
    window.location.href = `mailto:contact@infinitemind.tech?subject=${subject}&body=${body}`;
  };

  const CatalogCTA = ({ className = '' }: { className?: string }) => (
    <motion.button
      onClick={handleCatalogRequest}
      className={`w-full px-6 py-4 bg-primary/10 hover:bg-primary/20 text-primary rounded-lg flex items-center justify-center space-x-3 transition-colors ${className}`}
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
    >
      <Download className="w-5 h-5" />
      <span>Request Services Catalog</span>
    </motion.button>
  );

  const VisualShowcase = ({ className = '' }: { className?: string }) => (
    <div className={`grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 ${className}`}>
      {visualShowcase.map((item, index) => (
        <FadeIn key={index} delay={0.1 * index}>
          <motion.div
            className="relative group overflow-hidden rounded-lg"
            whileHover={{ y: -5 }}
            transition={{ duration: 0.3 }}
          >
            <div className="aspect-[4/3] relative">
              <img 
                src={item.image}
                alt={item.title}
                className="object-cover w-full h-full transform group-hover:scale-110 transition-transform duration-700"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent opacity-60" />
              <div className="absolute bottom-0 left-0 p-4">
                <h4 className="text-lg font-light text-white group-hover:text-primary transition-colors">
                  {item.title}
                </h4>
                <p className="text-sm text-white/70">{item.description}</p>
              </div>
            </div>
          </motion.div>
        </FadeIn>
      ))}
    </div>
  );

  const OffersList = ({ className = '' }: { className?: string }) => (
    <div className={`space-y-3 ${className}`}>
      {offers.map((offer, index) => (
        <FadeIn key={index} delay={0.1 * index}>
          <Link to={offer.link}>
            <motion.div
              className="group cursor-pointer"
              whileHover={{ x: 5 }}
              transition={{ duration: 0.2 }}
              onClick={(e) => {
                e.preventDefault();
                setExpandedItem(expandedItem === offer.title ? null : offer.title);
              }}
            >
              <div className="relative">
                <div className="absolute -inset-0.5 bg-gradient-to-r from-primary/20 to-primary-light/20 rounded-lg blur opacity-0 group-hover:opacity-100 transition duration-500" />
                
                <div className="relative bg-dark rounded-lg p-4">
                  <div className="flex items-center space-x-3">
                    <div className="text-primary/70 group-hover:text-primary transition-colors">
                      {offer.icon}
                    </div>
                    <div className="flex-1 min-w-0">
                      <h4 className="text-white/90 group-hover:text-primary transition-colors text-sm font-light">
                        {offer.title}
                      </h4>
                      <p className="text-white/50 text-xs truncate">
                        {offer.description}
                      </p>
                    </div>
                    <ArrowRight className={`w-4 h-4 text-primary transition-all ${
                      expandedItem === offer.title ? 'rotate-90' : ''
                    }`} />
                  </div>

                  <AnimatePresence>
                    {expandedItem === offer.title && offer.subItems && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                        className="mt-4 pl-9 space-y-2 overflow-hidden"
                      >
                        {offer.subItems.map((item, i) => (
                          <motion.div
                            key={i}
                            initial={{ x: -20, opacity: 0 }}
                            animate={{ x: 0, opacity: 1 }}
                            transition={{ delay: i * 0.1 }}
                            className="text-sm text-white/50 hover:text-white/70 transition-colors"
                          >
                            • {item}
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </motion.div>
          </Link>
        </FadeIn>
      ))}
    </div>
  );

  return (
    <AnimatePresence>
      {isVisible ? (
        <motion.div 
          className="fixed left-8 top-32 z-40 w-96 hidden xl:block"
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -100 }}
          transition={{ duration: 0.5 }}
        >
          <div className="bg-dark-lighter/80 backdrop-blur-sm rounded-lg p-4 border border-primary/10">
            {/* Header */}
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-2">
                <Package className="w-5 h-5 text-primary" />
                <h3 className="text-lg font-light">Why InfiniteMind?</h3>
              </div>
              <div className="flex items-center space-x-2">
                <motion.button
                  onClick={() => setIsFullscreen(true)}
                  className="text-white/50 hover:text-white transition-colors"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <Maximize2 className="w-4 h-4" />
                </motion.button>
                <motion.button
                  onClick={() => setIsVisible(false)}
                  className="text-white/50 hover:text-white transition-colors"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                >
                  <X className="w-4 h-4" />
                </motion.button>
              </div>
            </div>

            {/* Visual Showcase */}
            <div className="mb-6">
              <div className="aspect-video relative rounded-lg overflow-hidden">
                <img 
                  src="https://images.unsplash.com/photo-1557804506-669a67965ba0?auto=format&fit=crop&q=80&w=2000"
                  alt="Digital Innovation"
                  className="object-cover w-full h-full"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-dark via-dark/50 to-transparent" />
                <div className="absolute bottom-0 left-0 p-4">
                  <h4 className="text-2xl font-light text-white mb-2">Transform Your Business</h4>
                  <p className="text-white/70">Comprehensive digital solutions for modern enterprises</p>
                </div>
              </div>
            </div>

            {/* Offers List */}
            <div className="max-h-[calc(100vh-500px)] overflow-y-auto scrollbar-thin scrollbar-thumb-primary/20 scrollbar-track-transparent pr-2">
              <OffersList />
            </div>

            <div className="mt-6 space-y-4">
              <CatalogCTA />
              
              <motion.div
                className="border-t border-white/5 pt-4"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6 }}
              >
                <Link 
                  to="/consulting"
                  className="flex items-center justify-center space-x-2 text-primary text-sm hover:text-primary-light transition-colors"
                >
                  <span>Learn More</span>
                  <ArrowRight className="w-4 h-4" />
                </Link>
              </motion.div>
            </div>
          </div>

          <Dialog.Root open={isFullscreen} onOpenChange={setIsFullscreen}>
            <Dialog.Portal>
              <Dialog.Overlay asChild>
                <motion.div
                  className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                />
              </Dialog.Overlay>
              
              <Dialog.Content asChild>
                <motion.div
                  className="fixed inset-4 md:inset-8 bg-dark-lighter rounded-lg shadow-xl z-50 overflow-hidden"
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="h-full flex flex-col relative">
                    <div className="p-6 md:p-8 border-b border-primary/10 flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <Package className="w-6 h-6 text-primary" />
                        <Dialog.Title className="text-2xl font-light">
                          InfiniteMind Foundations
                        </Dialog.Title>
                      </div>
                      <Dialog.Close asChild>
                        <motion.button
                          className="text-white/50 hover:text-white transition-colors"
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.9 }}
                          aria-label="Close dialog"
                        >
                          <X className="w-6 h-6" />
                        </motion.button>
                      </Dialog.Close>
                    </div>

                    <Dialog.Description className="sr-only">
                      Explore InfiniteMind's comprehensive suite of foundation services and solutions
                    </Dialog.Description>

                    <div className="flex-1 overflow-y-auto p-6 md:p-8">
                      <div className="max-w-6xl mx-auto">
                        {/* Visual Showcase in Fullscreen */}
                        <VisualShowcase />
                        
                        {/* Offers Grid */}
                        <OffersList className="grid grid-cols-1 md:grid-cols-2 gap-6" />
                        
                        <div className="mt-8">
                          <CatalogCTA className="max-w-md mx-auto" />
                        </div>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </Dialog.Content>
            </Dialog.Portal>
          </Dialog.Root>
        </motion.div>
      ) : (
        <motion.button
          className="fixed left-8 top-32 z-40 hidden xl:block"
          onClick={() => setIsVisible(true)}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          aria-label="Open offers sidebar"
        >
          <div className="bg-primary text-white p-3 rounded-full shadow-lg">
            <Package className="w-6 h-6" />
          </div>
        </motion.button>
      )}
    </AnimatePresence>
  );
}