import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';

export default function ProgressBar() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadProgress, setLoadProgress] = useState(0);
  const location = useLocation();

  // Simulate page load progress
  useEffect(() => {
    setIsLoading(true);
    setLoadProgress(0);
    
    const interval = setInterval(() => {
      setLoadProgress(prev => {
        if (prev >= 100) {
          clearInterval(interval);
          setTimeout(() => setIsLoading(false), 200);
          return 100;
        }
        return Math.min(prev + Math.random() * 20, 100);
      });
    }, 200);

    return () => clearInterval(interval);
  }, [location.pathname]);

  return (
    // Right Progress Bar for Vertical Scroll
    <div className="fixed top-0 right-0 bottom-0 z-50 w-[2px]">
      <motion.div
        className="origin-top h-full bg-primary/30"
        initial={{ scaleY: 0 }}
        animate={{ scaleY: 1 }}
        transition={{ duration: 0.2 }}
      >
        <motion.div
          className="w-full bg-primary"
          style={{
            scaleY: isLoading ? loadProgress / 100 : 0,
            transformOrigin: "top"
          }}
          transition={{ duration: 0.2 }}
        />
      </motion.div>
    </div>
  );
}